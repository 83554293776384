<template>
    <div class="container">
        <div class="info-container">
            <div class="info-title">
                <span>{{detail.name || ''}}</span>
            </div>
            <div class="info-sub">
                <div class="info-sub-item">
                    <span class="key">注册时间: </span>
                    <span class="value">{{ detail.create_time | timeFilter }}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">联系人姓名: </span>
                    <span class="value">{{detail.contact_name || '-'}}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">联系人电话: </span>
                    <span class="value">{{detail.contact_phone || '-'}}</span>
                </div>
            </div>
        </div>
        <div class="tabs-container">
            <el-tabs class="my-tabs" v-model="activeTab">
                <el-tab-pane label="账号管理" name="account">
                    <org-account :org-id="id" v-if="activeTab === 'account'"/>
                </el-tab-pane>
                <el-tab-pane label="学校管理" name="school">
                    <org-school :org-id="id" v-if="activeTab === 'school'"/>
                </el-tab-pane>
                <el-tab-pane label="学生管理" name="student">
                    <org-student :org-id="id" v-if="activeTab === 'student'"/>
                </el-tab-pane>
                <el-tab-pane label="订单列表" name="order" v-if="auth('ADMIN')">
                    <org-order :org-id="id" v-if="activeTab === 'order'"/>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Core from '../../core';

export default {
    components: {
        OrgAccount: () => import('./components/OrgAccount'),
        OrgSchool: () => import('./components/OrgSchool'),
        OrgStudent: () => import('./components/OrgStudent'),
        OrgOrder: () => import('./components/OrgOrder'),

        TestManagement: () => import('./components/TestManagement'),
    },
    data() {
        return {
            id: '',
            detail: {},
            activeTab: 'account',
        };
    },
    watch: {},
    computed: {},
    created() {
        this.id = this.$route.query.id ||  Core.Data.getBelong().id;
        this.detail = this.$route.query.detail ? JSON.parse(this.$route.query.detail) : Core.Data.getBelong()
        if (this.auth('ADMIN')) {
            this.getOrgDetail();
        }
    },
    mounted() {},
    methods: {
        auth: Core.Utils.auth,
        getOrgDetail() {
            Core.Api.Org.detail(this.id).then((res) => {
                this.detail = res.detail;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container {
        width: 100%;
        background: #ffffff;
        box-sizing: border-box;
        padding: 0 30px;
        border-bottom: 0;
        .info-title {
            padding-top: 20px;
            font-size:20px;
            font-weight:500;
            color:$color-black;
            line-height:28px;
        }
        .info-sub {
            @include flex(row, space-between, center);
            flex-wrap: wrap;
            padding-top: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;
            &-item{
                width: 250px;
                span{
                    font-size: $font-size-base;
                    font-weight: 400;
                    line-height: 20px;
                }
                .key {
                    color:$color-black-light;
                }
                .value {
                    color:$color-black;
                }
            }
        }
    }
    .tabs-container {
        width: 100%;
        box-sizing: border-box;
    }
}
</style>